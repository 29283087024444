import React, { useState } from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout-minhelt/layout";
import Seo from "../components/Seo";
//import useViewportWidth from "../hooks/useViewportWidth"
import ReadMoreArrow from "../images/read-more.svg";
import DividerLigjtBlue from "../images/divider-ligt-blue.svg";
import ExpandArrow from "../images/arrow-vertical.svg";
import Hero from "../components/hero";
import Image from "../components/Image";

const { GATSBY_ACTIVE_ENV } = process.env

const IndexPage = ({ data, ...props }) => {
  const { /* newsItemImage, */ nodeNewsLobby, allNodeNews, allTaxonomyTermNews /* , news */ } = data;


  const [currentCategory, setCurrentCategory] = useState('*')
  const seo = {};

  const { title0, title1 } = nodeNewsLobby;
  const { hero } = nodeNewsLobby.relationships;

  const categories = allTaxonomyTermNews.nodes

  const nodes = allNodeNews.nodes.filter((n) => {
    if (currentCategory === '*') 
      return true;

    return `${n.relationships?.category?.tid}` === `${currentCategory}`
  }).filter((n) => {
    if (GATSBY_ACTIVE_ENV === 'production') {
      return n.status
    }
    else {
      return true
    }
  })

  const main = nodes.filter((e) => !e.isAdditional);
  const additinal = nodes.filter((e) => e.isAdditional);

  const onChange = (e) => {
    setCurrentCategory(e.target.value)
  }
  return (
    <Layout>
      <Seo {...seo} />
      <Hero
        {...hero}
        className=" mt-[90px] lg:mt-[126px] bg-hero "
        url={hero.relationships.image.localFile.publicURL}
      />

      <section className="  py-12 lg:py-32 ">
        <div className="container px-1 lg:px-6 flex flex-col lg:flex-row lg:space-x-8 lg:space-x-reverse">
          <div className="lg:w-2/3  lg:space-y-20">
            <div>
              <div className="text-blue text-[34px] font-bold">{title0}</div>
              <div className="inline pl-2">הצג</div>
<select onChange={onChange} defaultValue="*" className="p-1 inline">
  <option key="*" value="*">הכל</option>
          {categories.map((category,index) => (
            <option className="" key={index} value={category.tid}>{category.name}</option>
          ))}
          </select>
            </div>
            <div className="space-y-12">
              {main.map((item, index) => (
                <Item1 {...item} key={index} />
              ))}
            </div>
          </div>
          <div className="lg:w-1/3  lg:space-y-20">
            {additinal.langth > 0 && (
              <h2 className="text-blue text-[34px]">{title1}</h2>
            )}
            <div className="space-y-5">
              {additinal.map((item, index) => (
                <Item2 {...item} key={index} />
              ))}
            </div>
          </div>
        </div>
      </section>

      {
        /* width !== 0 && */ <React.Suspense fallback="loading..."></React.Suspense>
      }
    </Layout>
  );
};

export default IndexPage;

const Item1 = ({
  title,
  path,
  description,
  image,
  relationships,
  link,
  ...props
}) => {
  //console.log(link)

  const uri = path?.alias ? path?.alias : link ? link.uri : "/";

  const showLink1 = path && path?.alias;
  const showLink2 = !showLink1 && link && link?.uri;

  return (
    <div className="flex flex-col lg:flex-row lg:space-x-12 lg:space-x-reverse items-top ">
      <div className="min-w-[80px] lg:text-center px-2">
        <div className="mx-auto space-y-3">
          <div className="text-lg">{props.month}</div>
          <img
            src={DividerLigjtBlue}
            alt=""
            className="lg:text-center lg:mx-auto"
          />
          <div className="text-2xl">{props.day}</div>
        </div>
      </div>
      <div className="lg:max-w-[341px]">
        <div className=" relative m-[10px]  ">
          <div className="w-[80px] h-[80px] bg-blue absolute top-[-10px] right-[-10px] -z-10"></div>
          <div className="w-[80px] h-[80px] bg-yellow absolute bottom-[-10px] left-[-10px] -z-10"></div>
          <div className=" ">
            <Image image={relationships.image} alt="" className="lg:inline" />
          </div>
        </div>
      </div>
      <div className="lg:max-w-[340px]">
        <h2 className="text-2xl text-black font-normal">{title}</h2>
        <p className="text-lg py-4 text-justify">{description}</p>

        {showLink1 && (
          <Link
            to={uri}
            className="text-yellow2 text-base"
            aria-label={"המשך קריאה " + title}
          >
            {"המשך קריאה"} <img src={ReadMoreArrow} alt="" className="inline" />
          </Link>
        )}

        {showLink2 && (
          <a
            href={uri}
            className="text-yellow2 text-base"
            target="__blank"
            aria-label={"המשך קריאה " + title}
          >
            {"המשך קריאה"} <img src={ReadMoreArrow} alt="" className="inline" />
          </a>
        )}

        {/* 
        {uri === '/' &&
          <Link
            to={uri}
            className="text-yellow2 text-base"
            aria-label={"המשך קריאה " + title}
            >
            {"המשך קריאה"} <img src={ReadMoreArrow} alt="" className="inline" />
          </Link>
        }

        {uri !== '/' &&
          <a
            href={uri}
            className="text-yellow2 text-base"
            target="__blank"
            aria-label={"המשך קריאה " + title}
          >
            {"המשך קריאה"} <img src={ReadMoreArrow} alt="" className="inline" />
          </a>
        } */}
      </div>
    </div>
  );
};

const Item2 = ({ title, description, date, ...props }) => {
  const [show, setShow] = useState(false);

  return (
    <div className="border-r border-r-[3px] border-yellow pr-4">
      <button onClick={(e) => setShow(!show)} className="text-base">
        <span>{date}</span>
        <img
          src={ExpandArrow}
          alt=""
          className={
            "inline px-2 transition " + (show ? " rotate-180  " : " rotate-0 ")
          }
        />
      </button>

      <h2 className={"text-lg " + (show ? " text-blue " : "")}>{title}</h2>
      <p className={"text-[#787878] text-base " + (show ? " " : " hidden")}>
        {description}
      </p>
    </div>
  );
};

/* export const Head = () => <title>News Page</title> */

export const query = graphql`
  query newsQuery($id: String!) {
    nodeNewsLobby(id: { eq: $id }) {
      title
      title0: field_title
      title1: field_title1
      metatag {
        attributes {
          content
          name
        }
        tag
      }
      relationships {
        hero: field_hero {
          ...Hero
        }
      }
    }
    allNodeNews(sort: { order: DESC, fields: field_date }) {
      nodes {
        title
        path {
          alias
        }
        date: field_date(formatString: "D/M/YY")
        month: field_date(formatString: "MMMM")
        day: field_date(formatString: "D")
        description: field_description
        status
        isAdditional: field_additional_updates
        link: field_link {
          title
          uri
        }
        image: field_image {
          alt
        }
        relationships {
          image: field_image {
            ...Image
          }
          category: field_category {
            tid: drupal_internal__tid
          }
        }
      }
    }
    allTaxonomyTermNews {
    nodes {
      name
      tid: drupal_internal__tid
    }
  }
  }
`;
